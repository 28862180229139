.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, 
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

/**
 * Cards
 */

/* 
.MuiCardMedia-img {
  object-fit: contain !important;
  background-color: #f8f8f2;
}
*/
.MuiCardHeader-action {
  margin-bottom: -8px;
}
.MuiCardHeader-subheader {
  font-size: 0.8rem !important;
}

/**
 * Editor Markup
 */

 @media (min-width: 960px) {
   .app365-editor-content h1{
    font-size: 2.1422rem;
   }
   .app365-editor-content h2{
    font-size: 1.6667rem;
   }
   .app365-editor-content h3{
    font-size: 1.4996rem;
   }
   .app365-editor-content h4{
    font-size: 1.2146rem;
   }
 }
 @media (min-width: 600px) {
   .app365-editor-content h1{
    font-size: 1.928rem;
   }
   .app365-editor-content h2{
    font-size: 1.6667rem;
   }
   .app365-editor-content h3{
    font-size: 1.2853rem;
   }
   .app365-editor-content h4{
    font-size: 1.2146rem;
   }
 }
.app365-editor-content {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #546e7a;
}
.app365-editor-content h1 {
  font-weight: 500;
  font-size: 1.59375rem;
  letter-spacing: -0.24px;
  line-height: 1.167;
  margin-top: 15px;
  margin-bottom: 20px;
}
.app365-editor-content h2 {
  font-weight: 500;
  font-size: 1.40625rem;
  letter-spacing: -0.24px;
  line-height: 1.2;
  margin-top: 15px;
  margin-bottom: 16px;
}
.app365-editor-content h3 {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: -0.06px;
  line-height: 1.167;
  margin-top: 15px;
  margin-bottom: 12px;
}
.app365-editor-content h4 {
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: -0.06px;
  line-height: 1.235;
  margin-top: 15px;
  margin-bottom: 10px;
}
.app365-editor-content h5 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.05px;
  line-height: 1.334;
  margin-top: 15px;
  margin-bottom: 9px;
}
.app365-editor-content h6 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.05px;
  line-height: 1.6;
  margin-top: 15px;
  margin-bottom: 8px;
}
.app365-editor-content p {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  margin-bottom: 8px;
}
.app365-editor-content img {
  max-width: 100%;
  height: auto;
}

/**
 * Editor and DraftJS (React Draft Wysiwyg)
 */

.rdw-link-modal,
.rdw-image-modal,
.rdw-emoji-modal,
.rdw-embedded-modal {
  left: -200px;
}
.rdw-colorpicker-modal {
  left: -140px;
}
.rdw-embedded-modal-header-option {
  width: auto;
}
.rdw-link-modal-input,
.rdw-image-modal-url-input,
.rdw-image-modal-alt-input,
.rdw-image-modal-size-input,
.rdw-embedded-modal-link-input,
.rdw-embedded-modal-size-input {
  padding: 5px;
}
.rdw-link-modal {
  height: auto;
}

/**
 * Make formats of Quill Editor availabel on HTML-content
 * Quill Release v1.3.7, theme: Snow
 */

pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
}
blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-video {
  display: block;
  max-width: 100%;
}
.ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
